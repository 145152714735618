import styled from '../../styles/styled-components';
import Button from './Button';

const RaiseButton = styled(Button)`
  padding: 1.25rem 3rem;
  &:hover {
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
  }
`;

export default RaiseButton;
