import React from 'react';
import styled from '../../styles/styled-components';
import RaiseButton from './RaiseButton';

const RaiseButtonStyled = styled(RaiseButton)`
  margin-bottom: 8px;
  width: 235px;
`;
const Text = styled.div`
  font-size: 14px;
  text-align: center;
`;

const StartLoginButton = ({ ...props }: React.HTMLAttributes<HTMLElement>) => (
  <div {...props}>
    <RaiseButtonStyled href="https://app.enbanca.co/registro">
      Empieza ahora
    </RaiseButtonStyled>
    <Text>
      ¿Ya eres usuario? <a href="https://app.enbanca.co/login">ingresa aquí</a>
    </Text>
  </div>
);

export default StartLoginButton;
